import { template as template_95a54a075cb4492fab0e4b522b7976c2 } from "@ember/template-compiler";
const FKLabel = template_95a54a075cb4492fab0e4b522b7976c2(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
