import { template as template_ae3c55ff242d432ea5a50d5d6060495a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_ae3c55ff242d432ea5a50d5d6060495a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
