import { template as template_1f109bb722b441858ecf5c90c88b45c3 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1f109bb722b441858ecf5c90c88b45c3(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
